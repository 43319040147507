import React, {useState, useEffect} from 'react';
import {StripeElements} from './StripeElements';
import {dataSourceV2} from "commons-ui/core/DataSource";



export const StripeProvider = ({amount, products, noLoading, deliveryType, ecommerceStoreId, onInit, statesInclude, onRedirect, recaptchaKey, loading, onCreateOrder, checkoutValidate, onConfirmOrder, apiPath}) => {
  const [publicKey, setPublicKey] = useState("");


    const getPublicKey = () => {
        if (!ecommerceStoreId) {
            return;
        }
        dataSourceV2(`customer_payment_stripe_token_get?ecommerce_store_id=${ecommerceStoreId}`, {url: apiPath})
            .then(v => {
                console.log(12232132, v, v.token)
                if (v.token) {
                    setPublicKey(v.token);
                }
            });
    };


    useEffect(() => {
        getPublicKey();
    }, []);

    if (!publicKey) {
        return null
    }


  return (
    <StripeElements publicKey={publicKey} deliveryType={deliveryType} loading={loading} amount={amount} statesInclude={statesInclude} products={products} recaptchaKey={recaptchaKey} onInit={onInit} noLoading={noLoading} onRedirect={onRedirect} onCreateOrder={onCreateOrder} onConfirmOrder={onConfirmOrder} ecommerceStoreId={ecommerceStoreId} checkoutValidate={checkoutValidate} apiPath={apiPath}/>
  );
}