import React, {useState, useEffect} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import {StripeCheckout} from './StripeCheckout';



export const StripeElements = ({publicKey, amount, products, noLoading, deliveryType, ecommerceStoreId, recaptchaKey, statesInclude, onCreateOrder, checkoutValidate, loading, onConfirmOrder, onRedirect, onInit, apiPath}) => {
  const [stripePromise, setStripePromise] = useState(loadStripe(publicKey));
  const [amountState, setAmountState] = useState(Math.round(amount * 100));

  const onAmountChange = (amount) => {
    if (amount < 1000) {
      amount = amount * 100
    }
    setAmountState(Math.round(amount));
  };

  console.log("AMOUNT CHANGED", amountState)

  const options = {
    mode: 'payment',
    amount: amountState,
    currency: 'usd',
    //appearance: {}
  };


  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeCheckout amount={amountState} deliveryType={deliveryType} onAmountChange={onAmountChange} products={products} loading={loading} onInit={onInit} statesInclude={statesInclude} recaptchaKey={recaptchaKey} noLoading={noLoading} onRedirect={onRedirect} checkoutValidate={checkoutValidate} onCreateOrder={onCreateOrder} onConfirmOrder={onConfirmOrder} ecommerceStoreId={ecommerceStoreId} apiPath={apiPath}/>
    </Elements>
  );
}